
import Vue from "vue";
import SignUpCompleteProfile from "@/components/SignUp/SignUpCompleteProfile.vue";

export default Vue.extend({
  name: "SignUpMentoree",
  components: {
    SignUpCompleteProfile
  },
  data() {
    return {
      isPlay: false,
      videoId: "tnpXX1Xp1vM"
    };
  },
  computed: {
    player() {
      //@ts-ignore
      return this.$refs.youtube.player;
    }
  },
  methods: {
    playVideo() {
      //@ts-ignore
      this.isPlay = true;
      //@ts-ignore
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    }
  }
});
